@charset "UTF-8";
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 3.46667vw;
  line-height: 1.875;
  color: #333;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: auto;
  overflow-x: hidden; }

img {
  max-width: 100%; }

.block-show {
  display: block !important;
  width: 100%;
  box-sizing: border-box; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.tips {
  color: #FF6101;
  font-size: 3.2vw; }

.tips.sm-size {
  font-size: 4.26667vw; }

.page-title {
  font-size: 5.6vw;
  color: #333;
  margin-bottom: 4vw;
  font-weight: bold; }

.page-content {
  font-size: 3.73333vw;
  color: #30363d; }
  .page-content p {
    margin-bottom: 5.33333vw;
    font-size: 3.73333vw; }

.fixed {
  position: fixed;
  z-index: 1103; }

.full-screen {
  width: 100vw;
  min-height: 100vh;
  background: #fff;
  box-sizing: border-box; }

.scroll-fixed {
  overflow: hidden;
  position: fixed;
  width: 100vw;
  height: 100vh; }

.icon-404 {
  width: 37.33333vw;
  height: 37.33333vw;
  margin: 0 auto 13.33333vw;
  background: url("/404.png") no-repeat center center;
  background-size: contain; }

.link {
  color: #119cfe; }

.list-gutter > span,
.list-gutter > div,
.list-gutter > p {
  margin-right: 3.2vw; }
  .list-gutter > span:last-child,
  .list-gutter > div:last-child,
  .list-gutter > p:last-child {
    margin-right: 0; }

.list-file li {
  padding: 3.2vw 0 3.2vw 8vw;
  box-shadow: 0 1px 0 #e6e6e6;
  font-size: 4vw;
  color: #333;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background: url("/file.svg") no-repeat left center;
  background-size: 5.86667vw 5.86667vw; }

.am-carousel-wrap-dot > span {
  border-radius: 0;
  width: 4vw;
  height: 0.53333vw; }

.am-carousel-wrap-dot-active > span {
  background: #2470ec; }

.close {
  position: relative;
  width: 8vw;
  height: 8vw;
  background: url("/close.png") no-repeat center center; }

.btn {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  appearance: none;
  border: none;
  background-color: transparent;
  padding: 2.66667vw; }

.btn-back {
  line-height: 10.66667vw;
  text-align: center;
  background: #999;
  display: block;
  color: #fff;
  font-weight: bold; }

.btn-text {
  text-decoration: none;
  outline: none;
  appearance: none;
  border: none;
  background: transparent;
  text-align: center;
  color: #999;
  cursor: pointer; }

.am-modal-title {
  line-height: 1.5; }

.visible-hide {
  visibility: hidden; }

.icon {
  display: inline-block;
  vertical-align: middle;
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  appearance: none; }
  .icon.icon-online, .icon.icon-back, .icon.icon-search {
    width: 10.66667vw;
    height: 10.66667vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; }
  .icon.icon-online {
    background-image: url("/icon/online.png"); }
  .icon.icon-back {
    background-image: url("/icon/arrow-left.png"); }
  .icon.icon-search {
    background-image: url("/icon/search.png"); }
  .icon.icon-arrow-right {
    background: url("/icon/arrow-right.png") no-repeat;
    background-size: contain;
    width: 2.13333vw;
    height: 3.73333vw; }

/*----- flex 容器 ----*/
.flex-container {
  display: flex; }

.flex-space-between {
  justify-content: space-between; }

.flex-space-around {
  justify-content: space-around; }

.flex-x-center {
  justify-content: center; }

.flex-y-start {
  align-items: flex-start; }

.flex-x-end {
  justify-content: flex-end; }

.flex-y-end {
  align-items: flex-end; }

.flex-y-center {
  align-items: center; }

.flex-center {
  align-items: center;
  justify-content: center; }

.layout-vertical {
  flex-direction: column; }

.flex-wrap {
  flex-wrap: wrap; }

.flex-block {
  flex-grow: 1; }

.flex-shrink {
  flex-shrink: 1; }

.flex-fixed {
  flex-shrink: 0;
  flex-grow: 0; }

.plane {
  padding: 6.4vw 4.26667vw;
  background: #fff; }

.full-screen {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box; }
